export default [
    {
        path: 'pages/error/error-404',
        name: 'error-404',
        component: () => import('@/views/pages/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/pages/miscellaneous/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/pages/miscellaneous/error',
        name: 'misc-error',
        component: () => import('@/views/pages/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },
    //  brands
    {
        path: '/pages/brands/BrandsList',
        name: 'pages-brands-list',
        component: () => import('@/views/pages/brands/BrandsList.vue'),

    },
    //  scripts
    {
        path: '/pages/scripts',
        name: 'apps-scripts',
        component: () => import('@/views/pages/scripts/ScriptsList.vue'),
    },

    //  Blog
    {
        path: '/pages/blog/list',
        name: 'pages-blog-list',
        component: () => import('@/views/pages/blog/BlogListTable.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/comments',
        name: 'pages-blog-comments',
        component: () => import('@/views/pages/blog/comments/BlogComments.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/comments/:id',
        name: 'pages-blog-comments-edit',
        component: () => import('@/views/pages/blog/comments/BlogCommentEdit.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/pages/blog/create',
        name: 'pages-blog-create',
        component: () => import('@/views/pages/blog/BlogCreate.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/edit/:id',
        name: 'pages-blog-edit',
        component: () => import('@/views/pages/blog/BlogEdit.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/SEO/:id',
        name: 'pages-blog-SEO',
        component: () => import('@/views/pages/blog/BlogSEO.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/category/SEO/:id',
        name: 'pages-blog-category-SEO',
        component: () => import('@/views/pages/blog/BlogCategorySEO.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/commentsById/:id',
        name: 'pages-blog-commentsById',
        component: () => import('@/views/pages/blog/BlogCommentsById.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/categories/list',
        name: 'pages-blog-categories-list',
        component: () => import('@/views/pages/blog/categories/categories-list.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/categories/edit/:id',
        name: 'pages-blog-categories-edit',
        component: () => import('@/views/pages/blog/categories/categories-edit.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/categories/priorities/:categorySeourl/:id',
        name: 'pages-blog-categories-priorities',
        component: () => import('@/views/pages/blog/categories/categories-priority.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/categories/add',
        name: 'pages-blog-categories-add',
        component: () => import('@/views/pages/blog/categories/categories-add.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    {
        path: '/pages/blog/tags/list',
        name: 'pages-blog-tags-list',
        component: () => import('@/views/pages/blog/tag/TagsList.vue'),
        meta: {
            resource: 'blog',
            action: 'blog',
        },
    },
    //  shopRequests Pages
    {
        path: '/pages/shop/ShopRequests',
        name: 'pages-shop-ShopRequests',
        component: () => import('@/views/pages/shop/ShopRequests.vue'),
    },

    {
        path: '/pages/shop/ShopRequestSingle/:id',
        name: 'pages-shop-ShopRequestSingle',
        component: () => import('@/views/pages/shop/ShopRequestSingle.vue'),
    },
    {
        path: '/pages/shop/skills',
        name: 'pages-shop-skills',
        component: () => import('@/views/pages/shop/Skills.vue'),
    },

    //  products Pages
    {
        path: '/pages/products/CategoryProperties',
        name: 'pages-products-properties',
        component: () => import('@/views/pages/products/CategoryProperties.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/OptionValues',
        name: 'pages-products-optionValues',
        component: () => import('@/views/pages/products/OptionValues.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/ProductsCategory',
        name: 'pages-products-ProductsCategory',
        component: () => import('@/views/pages/products/ProductsCategory.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/ProductsCategory/:id',
        name: 'pages-products-ProductsCategory-id',
        component: () => import('@/views/pages/products/ProductsCategoryId.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/ProductsCreate',
        name: 'pages-products-ProductsCreate',
        component: () => import('@/views/pages/products/ProductsCreate.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/ProductsList',
        name: 'pages-products-ProductsList',
        component: () => import('@/views/pages/products/ProductsList.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/comments/',
        name: 'pages-products-ProductsComment',
        component: () => import('@/views/pages/products/ProductCommentsById.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/SEO/:id',
        name: 'pages-products-SEO',
        component: () => import('@/views/pages/products/ProductSEO.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/ProductsCategory/SEO/:id',
        name: 'pages-ProductsCategory-SEO',
        component: () => import('@/views/pages/products/ProductCategorySEO.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/Discounts',
        name: 'pages-products-discounts',
        component: () => import('@/views/pages/products/Discounts.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/Coupons',
        name: 'pages-products-coupons',
        component: () => import('@/views/pages/products/Coupons.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    {
        path: '/pages/products/edit/:id',
        name: 'pages-products-edit',
        component: () => import('@/views/pages/products/ProductsEdit.vue'),
        meta: {
            resource: 'product',
            action: 'product',
        }
    },
    //  academy Pages
    {
        path: '/pages/academy/AcademyCategoryProperties',
        name: 'pages-academy-AcademyProperties',
        component: () => import('@/views/pages/academy/AcademyCategoryProperties.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/MyStudents/:id',
        name: 'pages-academy-MyStudents',
        component: () => import('@/views/pages/academy/MyStudents.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyOptionValues',
        name: 'pages-academy-AcademyOptionValues',
        component: () => import('@/views/pages/academy/AcademyOptionValues.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyCategory',
        name: 'pages-academy-AcademyCategory',
        component: () => import('@/views/pages/academy/AcademyCategory.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyCategory/:id',
        name: 'pages-academy-AcademyCategory-id',
        component: () => import('@/views/pages/academy/AcademyCategoryId.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyCreate',
        name: 'pages-academy-AcademyCreate',
        component: () => import('@/views/pages/academy/AcademyCreate.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyList',
        name: 'pages-academy-AcademyList',
        component: () => import('@/views/pages/academy/AcademyList.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
        // meta: {
        //   contentRenderer: 'sidebar-left-detached',
        //   contentClass: 'ecommerce-application',
        //   // pageTitle: 'محصولات',
        //   resource: 'ProductManager',
        //   action: 'ProductManager',
        //   // breadcrumb: [
        //   //   {
        //   //     text: 'لیست',
        //   //     active: true
        //   //   },
        //   // ],
        // },
        // component: () => import('@/views/pages/products/ServiceList.vue'),
    },
    {
        path: '/pages/academy/AcademyComments/',
        name: 'pages-academy-AcademyComment',
        component: () => import('@/views/pages/academy/AcademyCommentsById.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademySEO/:id',
        name: 'pages-academy-AcademySEO',
        component: () => import('@/views/pages/academy/AcademySEO.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyCategory/SEO/:id',
        name: 'pages-academy-AcademySEO',
        component: () => import('@/views/pages/academy/AcademyCategorySEO.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyDiscounts',
        name: 'pages-academy-AcademyDiscounts',
        component: () => import('@/views/pages/academy/AcademyDiscounts.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/coupon',
        name: 'pages-coupon',
        component: () => import('@/views/pages/coupon/index.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }
    },
    {
        path: '/pages/academy/AcademyEdit/:id',
        name: 'pages-academy-AcademyEdit',
        component: () => import('@/views/pages/academy/AcademyEdit.vue'),
        meta: {
            resource: 'academy',
            action: 'academy',
        }

    },
    //  service Pages
    {
        path: '/pages/service/ServiceCategoryProperties',
        name: 'pages-service-ServiceProperties',
        component: () => import('@/views/pages/service/ServiceCategoryProperties.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceOptionValues',
        name: 'pages-service-ServiceOptionValues',
        component: () => import('@/views/pages/service/ServiceOptionValues.vue'),
          meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceCategory',
        name: 'pages-service-ServiceCategory',
        component: () => import('@/views/pages/service/ServiceCategory.vue'),
          meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceCategory/:id',
        name: 'pages-service-ServiceCategory-id',
        component: () => import('@/views/pages/service/ServiceCategoryId.vue'),
          meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceCreate',
        name: 'pages-service-ServiceCreate',
        component: () => import('@/views/pages/service/ServiceCreate.vue'),
          meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceList',
        name: 'pages-service-ServiceList',
        component: () => import('@/views/pages/service/ServiceList.vue'),
          meta: {
            resource: 'service',
            action: 'service',
        }

    },
    {
        path: '/pages/service/ServiceComments/',
        name: 'pages-service-ServiceComment',
        component: () => import('@/views/pages/service/ServiceCommentsById.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceSEO/:id',
        name: 'pages-service-ServiceSEO',
        component: () => import('@/views/pages/service/ServiceSEO.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceCategory/SEO/:id',
        name: 'pages-service-ServiceSEO',
        component: () => import('@/views/pages/service/ServiceCategorySEO.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }
    },
    {
        path: '/pages/service/ServiceDiscounts',
        name: 'pages-service-ServiceDiscounts',
        component: () => import('@/views/pages/service/ServiceDiscounts.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }
    },

    {
        path: '/pages/service/ServiceEdit/:id',
        name: 'pages-service-ServiceEdit',
        component: () => import('@/views/pages/service/ServiceEdit.vue'),
        meta: {
            resource: 'service',
            action: 'service',
        }

    },
    // Shipping
    {
        path: '/pages/shipping/shippingType',
        name: 'pages-shipping-shippingType',
        component: () => import('@/views/pages/shipping/shippingType.vue'),
    },
    {
        path: '/pages/shipping/shippingMain',
        name: 'pages-shipping-shippingMain',
        component: () => import('@/views/pages/shipping/shippingMain.vue'),

    },
    // portfolio
    {
        path: '/pages/portfolio/PortfolioList',
        name: 'pages-portfolio-portfolioList',
        component: () => import('@/views/pages/portfolio/PortfolioList.vue'),
        meta: {
            resource: 'vendor',
            action: 'vendor',
        }
    },
    {
        path: '/pages/portfolio/PortfolioCreate',
        name: 'pages-portfolio-portfolioCreate',
        component: () => import('@/views/pages/portfolio/PortfolioCreate.vue'),
        meta: {
            resource: 'vendor',
            action: 'vendor',
        }
    },
    {
        path: '/pages/portfolio/PortfolioEdit/:id',
        name: 'pages-portfolio-portfolioEdit',
        component: () => import('@/views/pages/portfolio/PortfolioEdit.vue'),
        meta: {
            resource: 'vendor',
            action: 'vendor',
        }
    },
    // Comments
    {
        path: '/pages/comments',
        name: 'pages-comments',
        component: () => import('@/views/pages/comments/CommentsList.vue'),
    },
    // Reports
    {
        path: '/pages/reports',
        name: 'pages-reports',
        component: () => import('@/views/pages/reports/ReportsList.vue'),
    },
]
