export default [

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/chats',
        name: 'apps-chats',
        component: () => import('@/views/apps/chat/Chats.vue'),
        title: 'چت ها',
        meta: {
            resource: 'Support',
            action: 'Support',
            pageTitle: 'چت ها',
            // breadcrumb: [
            //   {
            //     text: 'لیست',
            //     active: true
            //   },
            // ],
        },
    },
    {
        path: '/apps/chats/:id',
        name: 'apps-chats-id',
        resource: 'Support',
        action: 'Support',
        component: () => import('@/views/apps/chat/Chat.vue'),
        meta: {
            // contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },


    //  Ticket
    {
        path: '/apps/allTickets',
        name: 'apps-allTickets',
        component: () => import('@/views/apps/Ticket/AllTickets.vue'),
    },
    {
        path: '/apps/ticketDetail/:id',
        name: 'apps-ticketDetail-id',
        component: () => import('@/views/apps/Ticket/Detail/TicketDetail.vue'),
    },


    //  User
    {
        path: '/apps/users/list',
        name: 'apps-users-list',
        component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    },
    {
        path: '/apps/users/edit/:id',
        name: 'apps-users-edit',
        component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    },

    //  Financial
    {
        path: '/apps/financial/payments/list',
        name: 'apps-financial-payments-list',
        component: () => import('@/views/apps/financial/payments/PaymentsList.vue'),
        meta: {
            resource: 'Accountent',
            action: 'Accountent',
        }
    },
    {
        path: '/apps/financial/wallets/list',
        name: 'apps-financial-wallets-list',
        component: () => import('@/views/apps/financial/wallets/WalletsList.vue'),
        meta: {
            resource: 'Accountent',
            action: 'Accountent',
        }
    },


    {
        path: '/apps/financial/wallets/info/:id',
        name: 'apps-financial-wallets-info',
        component: () => import('@/views/apps/financial/wallets/WalletInfo.vue'),
        meta: {
            resource: 'Accountent',
            action: 'Accountent',
        }
    },
    {
        path: '/apps/orders/list',
        name: 'apps-orders-list',
        component: () => import('@/views/apps/orders/OrdersList.vue'),
        meta: {
            resource: 'Accountent',
            action: 'Accountent',
        }
    },
    {
        path: '/apps/orders/info/:id',
        name: 'apps-orders-info',
        component: () => import('@/views/apps/orders/OrderInfo.vue'),
        meta: {
            resource: 'Accountent',
            action: 'Accountent',
        }
    },

    //  Profile
    {
        path: '/apps/profile',
        name: 'apps-profile',
        component: () => import('@/views/apps/profile/ProfileInfo.vue'),
        meta: {
            resource: 'vendor',
            action: 'vendor',
        }
    },
    //  Profile
    {
        path: '/apps/Gamification/Actions',
        name: 'apps-gamification-actions',
        component: () => import('@/views/pages/gamification/Actions.vue'),

    },
    {
        path: '/apps/Gamification/userScores',
        name: 'apps-gamification-userScores',
        component: () => import('@/views/pages/gamification/userScores.vue'),

    },

    //  Edit Pages
    {
        path: '/apps/home-settings',
        name: 'apps-home-settings',
        component: () => import('@/views/apps/edit-pages/Home.vue'),
    },
    {
        path: '/apps/Magazine-settings',
        name: 'apps-Magazine-settings',
        component: () => import('@/views/apps/edit-pages/Magazine.vue'),
    },
    {
        path: '/apps/products-settings',
        name: 'apps-products-settings',
        component: () => import('@/views/apps/edit-pages/products.vue'),
    },
    {
        path: '/apps/academy-settings',
        name: 'apps-academy-settings',
        component: () => import('@/views/apps/edit-pages/academy.vue'),
    },
    {
        path: '/apps/footer',
        name: 'apps-footer',
        component: () => import('@/views/apps/edit-pages/footer.vue'),
    },
    {
        path: '/apps/e-commerce/add-product-media/:id',
        name: 'apps-e-commerce-add-product-media',
        component: () => import('@/views/pages/academy/AddCourseMedia.vue'),
        meta: {contentClass: 'ecommerce-application'},
    },
    {
        path: '/apps/service-settings',
        name: 'apps-service-settings',
        component: () => import('@/views/apps/edit-pages/Services.vue'),
    },
    {
        path: '/apps/menu',
        name: 'apps-products-menu',
        component: () => import('@/views/apps/edit-pages/menu.vue'),
    },
]
