import {HubConnectionBuilder,LogLevel, HttpTransportType,} from "@microsoft/signalr";
import {inject} from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
const token = useJwt.getToken()
import store from '@/store'
import Helper from "@/libs/Helper";

    const notificationHub = new HubConnectionBuilder()
        .withUrl(Helper.baseUrl+"hub/notification", {

            // transport:HttpTransportType.WebSockets,
            // skipNegotiation:true,

            accessTokenFactory: function () {
                return token
            },

        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    inject("notificationHub", notificationHub);
    notificationHub.on("NewNotificationReceived", (res) => {
        store.commit('setNotificationObj',res)
        // store.commit("setNewNotification", res);
    });


    async function start() {
        try {
            await notificationHub.start();
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
        }
    };
    start();



