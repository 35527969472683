export default {
    state: () => {
        return {
           notificationObj:null
        };
    },
    getters: {
        getNotificationObj(state) {
            return state.notificationObj;
        },
    },
    mutations: {
        setNotificationObj(state, res) {
            state.notificationObj = res
            console.log(state.notificationObj)
        },
        clearNotification(state) {
            state.notificationObj = null
        }
    },
    actions: {

    },
};
